<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <registry-personal-data
        save-self
        :registry="registry"
        :editable="editable"
      />
      <octo-entity-addresses
        save-self
        :addresses="registry.addresses"
        :registry-id="registry.id"
        :editable="editable"
      />
    </div>
    <div class="col-12 col-md-6">
      <octo-entity-emails
        save-self
        :emails="registry.emails"
        :registry-id="registry.id"
        :editable="editable"
      />
      <octo-entity-phones
        save-self
        :phones="registry.phones"
        :registry-id="registry.id"
        :editable="editable"
      />
    </div>
  </div>
</template>

<script>
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import Registry from "@/models/registry";

export default {
  name: "RegistryTutorTabPane",
  components: {OctoEntityPhones, OctoEntityEmails, OctoEntityAddresses, RegistryPersonalData},
  props: {
    registry: {
      type: Object,
      default: () => this.$_.cloneDeep(Registry)
    },
    editable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
