import { render, staticRenderFns } from "./StudentArchiveRecapForm.vue?vue&type=template&id=efd8db62&scoped=true&"
import script from "./StudentArchiveRecapForm.vue?vue&type=script&lang=js&"
export * from "./StudentArchiveRecapForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efd8db62",
  null
  
)

export default component.exports