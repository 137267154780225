<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button
          :title="`${registry.name} ${registry.surname}`"
          :buttons="headerButtons"
          @onSaveStudent="saveStudent"
          @onGoToClassroom="goToClassroom"
          @onRollbackStudentToPendingStatus="rollbackStudentToPendingStatus"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <card body-classes="standard-card-body" class="shadow mb-3">
          <student-archive-recap-form ref="studentArchiveRecapForm" :editable="isTeachingManager"/>
        </card>
        <card class="shadow" body-classes="standard-card-body">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('fields.course') }}</h4>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component>
              <template slot="label">
                <div>
                  <label-theme-component>{{ course.code }}:</label-theme-component>
                  {{ course.title }}
                </div>
              </template>
            </list-group-item-component>
          </ul>
        </card>
        <octo-notes
          :key="`octo-notes-${studentDataKey}`"
          entity="student"
          :entity-id="student.id"
          :notes="student.notes"
        />
      </div>
      <div class="col-md-5">
        <student-status-container :student="student"/>
        <student-history :student-id="student.id" :key="`student-history-${studentDataKey}`"/>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import Registry from "@/models/registry";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import StudentStatusContainer from "@/pages/Students/components/StudentStatusContainer";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Course from "@/models/course";
import StudentHistory from "@/pages/Students/components/StudentHistory";
import OctoNotes from "@/components/OctoNotes";
import StudentArchive from "@/store/current/resources/studentArchive";
import {endpoints} from "@/routes/endpoints";
import StudentArchiveRecapForm from "@/pages/Students/components/StudentArchiveRecapForm";
import ClassroomTypes from "@/pages/Didactics/resources/classroomTypes";
import {permissionService} from "@/util/permission-service";

export default {
  name: "StudentArchiveRecap",
  components: {
    StudentArchiveRecapForm,
    OctoNotes,
    StudentHistory,
    LabelThemeComponent,
    ListGroupItemComponent,
    StudentStatusContainer,
    OctoHeaderButton,
  },
  data() {
    return {
      localStudent: this.$_.cloneDeep(StudentArchive),
      studentDataKey: 1,
      labelWidth: 180
    }
  },
  mounted() {
    this.localStudent = this.$_.cloneDeep(this.student);
  },
  computed: {
    ...mapGetters({
      student: 'current/studentArchive',
      allCourses: 'common/allCourses',
    }),

    isTeachingManager() {
      return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    },

    registry() {
      return this.student?.registry || this.$_.cloneDeep(Registry);
    },

    course() {
      return this.allCourses?.[this.student?.course_id] || this.$_.cloneDeep(Course);
    },

    mainClassroom() {
      return this.$_.find(this.student.classrooms, classroom => classroom.student_classroom === 'main') || {};
    },

    headerButtons() {
      if (!this.isTeachingManager) {
        return [];
      }

      const buttons = [
        {
          label: 'save',
          onClick: 'onSaveStudent'
        },
        {
          label: 'rollback_student',
          onClick: 'onRollbackStudentToPendingStatus',
          confirmRequired: true,
          confirmText: this.$t('confirm.rollback_student_to_pending_status')
        },
      ];

      if (this.mainClassroom?.id) {
        buttons.push({
          label: 'go_to_classroom',
          onClick: 'onGoToClassroom'
        });
      }

      return buttons;
    }
  },
  methods: {
    ...mapMutations({
      setPartialStudentArchive: 'current/setPartialStudentArchive'
    }),

    saveStudent() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.STUDENT_UPDATE_ARCHIVE.replace('{id}', this.localStudent.id),
        this.$refs.studentArchiveRecapForm.getStudentData()
      )
        .then((resp) => {
          this.setPartialStudentArchive({key: 'status', value: resp?.data?.data?.status});
          this.setPartialStudentArchive({key: 'accounting_approval', value: resp?.data?.data?.accounting_approval});
          this.studentDataKey++;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    goToClassroom() {
      if (this.mainClassroom.type === ClassroomTypes.certificate.value) {
        this.$router.push({name: 'didactics.classrooms.archive.certificate.show', params: {id: this.mainClassroom.id}});
      } else {
        this.$router.push({name: 'didactics.classrooms.on-going.show', params: {id: this.mainClassroom.id}});
      }
    },

    rollbackStudentToPendingStatus() {
      this.$fullLoading.show();

      this.$api.put(endpoints.STUDENT_ROLLBACK_TO_PENDING_STATUS.replace('{id}', this.localStudent.id))
        .then(() => {
          this.$fullLoading.hide();
          this.$router.push({name: 'students.waiting.show', params: {id: this.localStudent.id}});
        })
        .catch(() => {
          this.$fullLoading.hide();
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        });
    }
  }
}
</script>

<style scoped>

</style>
