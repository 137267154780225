<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button
          :title="`${registry.name} ${registry.surname}`"
          :buttons="headerButtons"
          @onOpenModalForMain="openModalForMain"
          @onOpenModalForGuest="openModalForGuest"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card
          v-if="mainClassrooms && mainClassrooms.length > 0"
          body-classes="standard-card-body" class="shadow mb-3">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.classroom_main') }}</h4>
          </div>
          <div>
            <el-table stripe :data="mainClassrooms">
              <el-table-column
                :label="$t('datatable.course')"
                align="left">
                <div slot-scope="{ row }">
                  <div v-for="(course, index) in row.courses" v-bind:key="`course-${index}`">
                    <div class="text-truncate">{{ courses[course.id] | optional('title') }}</div>
                    <div class="text-uppercase small">{{ $t('datatable.code') }}:
                      <label-theme-component>{{ course.code }}</label-theme-component>
                    </div>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                :label="$t('datatable.code')"
                align="center">
                <div slot-scope="{ row }">
                  {{ row.code }}
                </div>
              </el-table-column>
              <el-table-column
                :label="$t('datatable.schedulation')"
                align="left">
                <div slot-scope="{ row }">
                  {{ row.schedulation }}
                </div>
              </el-table-column>
            </el-table>
          </div>
        </card>

        <card
          v-if="guestClassrooms && guestClassrooms.length > 0"
          body-classes="standard-card-body" class="shadow mb-3">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('didactics.classrooms_guest_linked') }}</h4>
          </div>
          <div>
            <el-table stripe :data="guestClassrooms">
              <el-table-column
                :label="$t('datatable.course')"
                align="left">
                <div slot-scope="{ row }">
                  <div v-for="(course, index) in row.courses" v-bind:key="`course-${index}`">
                    <div class="text-truncate">{{ courses[course.id] | optional('title') }}</div>
                    <div class="text-uppercase small">{{ $t('datatable.code') }}:
                      <label-theme-component>{{ course.code }}</label-theme-component>
                    </div>
                  </div>
                </div>
              </el-table-column>
              <el-table-column
                :label="$t('datatable.code')"
                align="center">
                <div slot-scope="{ row }">
                  {{ row.code }}
                </div>
              </el-table-column>
              <el-table-column
                :label="$t('datatable.schedulation')"
                align="left">
                <div slot-scope="{ row }">
                  {{ row.schedulation }}
                </div>
              </el-table-column>
              <el-table-column
                width="150"
                align="right">
                <div slot-scope="{ row }" class="text-truncate">
                  <base-button
                    confirm-required
                    :confirm-title="$t('didactics.detach_student_as_guest')"
                    link
                    size="sm text-uppercase"
                    @click="detachStudentAsGuest(row)">
                    {{ $t('common.remove') }}
                  </base-button>
                </div>
              </el-table-column>

            </el-table>
          </div>
        </card>
      </div>
    </div>
    <student-lessons-recap
      :key="`student-lessons-recap-${studentDataKey}`"
      :classroom-lessons="mainClassroomLessons"
      :student-lessons="localStudent.lessons"
    />
    <div class="row">
      <div class="col-12">
        <card body-classes="standard-card-body" class="shadow mb-0">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{ $t('common.lesson_calendar') }}</h4>
          </div>
          <student-lessons
            :key="`student-lessons-${studentDataKey}`"
            :classroom-lessons="mainClassroomLessons"
            :student-lessons="localStudent.lessons"/>
        </card>
      </div>
    </div>
    <modal bodyClasses="p-1" modalClasses="modal-xl" centered :show.sync="showModal">
      <template slot="header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
      </template>
      <available-classrooms-datatable
        :mode="modeClassroomLink"
        ref="availableGuestClassroomsDatatableRef"
        v-if="showModal"
        @onLinked="() => { this.showModal = false }"
        :student="localStudent"
      />
    </modal>
  </div>
</template>

<script>

import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters, mapMutations} from "vuex";
import Registry from "@/models/registry";
import StudentLessons from "@/pages/Students/components/StudentLessons";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import StudentArchive from "@/store/current/resources/studentArchive";
import NoDataComponent from "@/components/NoDataComponent";
import Modal from "@/components/Modal";
import AvailableClassroomsDatatable from "@/pages/Students/datatables/AvailableClassroomsDatatable";
import {Table, TableColumn} from "element-ui";
import {endpoints} from "@/routes/endpoints";
import StudentLessonsRecap from "./StudentLessonsRecap";
import {permissionService} from "@/util/permission-service";

export default {
  name: "StudentArchiveClassroom",
  components: {
    StudentLessonsRecap,
    AvailableClassroomsDatatable,
    Modal,
    NoDataComponent,
    LabelThemeComponent,
    StudentLessons,
    OctoHeaderButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      localStudent: this.$_.cloneDeep(StudentArchive),
      mainClassroomLessons: [],
      studentDataKey: 1,
      guestClassrooms: [],
      mainClassrooms: [],
      showModal: false,
      modeClassroomLink: 'guest',
      modalTitle: this.$t('page.classrooms_on_going')
    }
  },
  watch: {
    student: {
      handler(val) {
        this.localStudent = this.$_.cloneDeep(this.student);
        this.mainClassroomLessons = this.$_.find(
          this.localStudent.classrooms || {}, {student_classroom: 'main'}
        )?.lessons || [];
        this.guestClassrooms = this.$_.filter(this.student.classrooms, (classroom) => {
          return classroom.student_classroom === 'guest';
        });
        this.mainClassrooms = this.$_.filter(this.student.classrooms, (classroom) => {
          return classroom.student_classroom === 'main';
        });
        this.studentDataKey++;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      student: 'current/studentArchive',
      courses: 'common/allCourses',
    }),

    registry() {
      return this.student?.registry || this.$_.cloneDeep(Registry);
    },

    headerButtons() {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE])) {
        buttons.push(
          {
            label: 'change_main',
            onClick: 'onOpenModalForMain'
          },
          {
            label: 'new_guest',
            onClick: 'onOpenModalForGuest'
          }
        );
      }

      return buttons;
    }
  },
  methods: {

    ...mapMutations({
      setPartialStudentArchive: 'current/setPartialStudentArchive'
    }),

    openModalForMain: function () {
      this.modeClassroomLink = 'main';
      this.showModal = true;
    },
    openModalForGuest: function () {
      this.modeClassroomLink = 'guest'
      this.showModal = true;
    },
    detachStudentAsGuest: function (classroom) {
      this.$fullLoading.show();

      this.$api.put(endpoints.STUDENT_HANDLE_CLASSROOM_LINK
          .replace('{id}', this.student.id)
          .replace('{classroomId}', classroom.id)
        + 'detach-as-guest'
      )
        .then((resp) => {

          this.setPartialStudentArchive({
            key: 'classrooms',
            value: resp?.data?.data?.classrooms
          })

        })
        .catch((e) => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    }
  }
}
</script>

<style scoped>

</style>
