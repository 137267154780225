<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.summary') }}</div>
              </div>
            </template>
            <student-archive-recap :key="studentDataKey"/>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('datatable.schedule_preferences') }}</div>
              </div>
            </template>
            <student-archive-schedule-preferences-tab/>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('didactics.classrooms_joined') }}</div>
              </div>
            </template>
            <student-archive-classroom/>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <octo-header-button
              :buttons="registryHeaderButtons"
              :title="`${registry.name} ${registry.surname}`"
              @onOpenRegistry="$router.push({name: 'registries.show', params: {id: student.registry.id}})"
            />
            <registry-tab-pane
              v-if="isTeachingManager"
              :registry="student.registry"
              :key="`registry-tab-pane-${studentDataKey}`"
            />
            <registry-tutor-tab-pane
              v-else
              :registry="student.registry"
              :key="`registry-tutor-tab-pane-${studentDataKey}`"
            />
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>

import {TabPane, Tabs} from "@/components";
import {endpoints} from "@/routes/endpoints";
import {mapMutations} from "vuex";
import StudentArchive from "@/store/current/resources/studentArchive";
import OctoNotes from "@/components/OctoNotes";
import StudentHistory from "@/pages/Students/components/StudentHistory";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import Registry from "@/models/registry";
import StudentArchiveRecap from "@/pages/Students/components/StudentArchiveRecap";
import StudentArchiveClassroom from "@/pages/Students/components/StudentArchiveClassroom";
import StudentArchiveGuestClassrooms from "@/pages/Students/components/StudentArchiveGuestClassrooms";
import {permissionService} from "@/util/permission-service";
import RegistryTutorTabPane from "@/components/TabPanels/RegistryTutorTabPane";
import StudentArchiveSchedulePreferencesTab from "@/pages/Students/components/StudentArchiveSchedulePreferencesTab";

export default {
  name: "ShowStudentArchivePage",
  components: {
    StudentArchiveSchedulePreferencesTab,
    RegistryTutorTabPane,
    StudentArchiveGuestClassrooms,
    StudentArchiveClassroom,
    StudentArchiveRecap,
    RegistryTabPane,
    OctoHeaderButton,
    StudentHistory,
    OctoNotes,
    Tabs,
    TabPane,
  },
  data() {
    return {
      student: this.$_.cloneDeep(StudentArchive),
      studentDataKey: 1
    }
  },
  computed: {
    isTeachingManager() {
      return permissionService.isAllowed([permissionService.TEACHING_MANAGER_ROLE]);
    },

    registry() {
      return this.student?.registry || this.$_.cloneDeep(Registry);
    },

    registryHeaderButtons() {
      const buttons = [];

      if (this.isTeachingManager) {
        buttons.push({onClick: 'onOpenRegistry', label: 'open_registry'});
      }

      return buttons;
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.student = this.$_.cloneDeep(StudentArchive);
    this.setStudentArchive(this.student);
    this.$api.get(endpoints.STUDENT_SHOW.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.student = {...this.student, ...resp?.data?.data}
        this.setStudentArchive(this.student);
        this.studentDataKey++;
      })
      .catch((e) => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        // await this.$router.push({name: 'students.waiting.index'})
      })
      .finally(() => {
          this.$fullLoading.hide();
        }
      )
  },
  methods: {
    ...mapMutations({
      setStudentArchive: 'current/setStudentArchive'
    }),
  }
}
</script>

<style scoped>

</style>
