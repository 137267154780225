var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('octo-header-button',{attrs:{"title":((_vm.registry.name) + " " + (_vm.registry.surname)),"buttons":_vm.headerButtons},on:{"onOpenModalForMain":_vm.openModalForMain,"onOpenModalForGuest":_vm.openModalForGuest}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.mainClassrooms && _vm.mainClassrooms.length > 0)?_c('card',{staticClass:"shadow mb-3",attrs:{"body-classes":"standard-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('didactics.classroom_main')))])]),_c('div',[_c('el-table',{attrs:{"stripe":"","data":_vm.mainClassrooms}},[_c('el-table-column',{attrs:{"label":_vm.$t('datatable.course'),"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},_vm._l((row.courses),function(course,index){return _c('div',{key:("course-" + index)},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm._f("optional")(_vm.courses[course.id],'title')))]),_c('div',{staticClass:"text-uppercase small"},[_vm._v(_vm._s(_vm.$t('datatable.code'))+": "),_c('label-theme-component',[_vm._v(_vm._s(course.code))])],1)])}),0)}}],null,false,3766041400)}),_c('el-table-column',{attrs:{"label":_vm.$t('datatable.code'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.code)+" ")])}}],null,false,2864900530)}),_c('el-table-column',{attrs:{"label":_vm.$t('datatable.schedulation'),"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.schedulation)+" ")])}}],null,false,4183940482)})],1)],1)]):_vm._e(),(_vm.guestClassrooms && _vm.guestClassrooms.length > 0)?_c('card',{staticClass:"shadow mb-3",attrs:{"body-classes":"standard-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('didactics.classrooms_guest_linked')))])]),_c('div',[_c('el-table',{attrs:{"stripe":"","data":_vm.guestClassrooms}},[_c('el-table-column',{attrs:{"label":_vm.$t('datatable.course'),"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},_vm._l((row.courses),function(course,index){return _c('div',{key:("course-" + index)},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm._f("optional")(_vm.courses[course.id],'title')))]),_c('div',{staticClass:"text-uppercase small"},[_vm._v(_vm._s(_vm.$t('datatable.code'))+": "),_c('label-theme-component',[_vm._v(_vm._s(course.code))])],1)])}),0)}}],null,false,3766041400)}),_c('el-table-column',{attrs:{"label":_vm.$t('datatable.code'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.code)+" ")])}}],null,false,2864900530)}),_c('el-table-column',{attrs:{"label":_vm.$t('datatable.schedulation'),"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.schedulation)+" ")])}}],null,false,4183940482)}),_c('el-table-column',{attrs:{"width":"150","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-truncate"},[_c('base-button',{attrs:{"confirm-required":"","confirm-title":_vm.$t('didactics.detach_student_as_guest'),"link":"","size":"sm text-uppercase"},on:{"click":function($event){return _vm.detachStudentAsGuest(row)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)}}],null,false,1133908536)})],1)],1)]):_vm._e()],1)]),_c('student-lessons-recap',{key:("student-lessons-recap-" + _vm.studentDataKey),attrs:{"classroom-lessons":_vm.mainClassroomLessons,"student-lessons":_vm.localStudent.lessons}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"shadow mb-0",attrs:{"body-classes":"standard-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('common.lesson_calendar')))])]),_c('student-lessons',{key:("student-lessons-" + _vm.studentDataKey),attrs:{"classroom-lessons":_vm.mainClassroomLessons,"student-lessons":_vm.localStudent.lessons}})],1)],1)]),_c('modal',{attrs:{"bodyClasses":"p-1","modalClasses":"modal-xl","centered":"","show":_vm.showModal},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.modalTitle))])]),(_vm.showModal)?_c('available-classrooms-datatable',{ref:"availableGuestClassroomsDatatableRef",attrs:{"mode":_vm.modeClassroomLink,"student":_vm.localStudent},on:{"onLinked":function () { this$1.showModal = false }}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }