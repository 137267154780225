<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <octo-header-button
          :title="`${registry.name} ${registry.surname}`"
          :buttons="headerButtons"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">

        <card body-classes="standard-card-body" class="shadow mb-3">
          <div slot="header">
            <h4 class="card-title text-capitalize">{{$t('didactics.classrooms_guest_available')}}</h4>
          </div>
          <available-classrooms-datatable
            ref="availableGuestClassroomsDatatableRef"
            v-if="localStudent.id"
            :student="localStudent" />
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import StudentArchive from "@/store/current/resources/studentArchive";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {mapGetters, mapMutations} from "vuex";
import Registry from "@/models/registry";
import AvailableClassroomsDatatable from "@/pages/Students/datatables/AvailableClassroomsDatatable";
import NoDataComponent from "@/components/NoDataComponent";
import {Table, TableColumn} from "element-ui";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "StudentArchiveGuestClassrooms",
  components: {
    LabelThemeComponent,
    NoDataComponent,
    AvailableClassroomsDatatable,
    OctoHeaderButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      localStudent: this.$_.cloneDeep(StudentArchive),
      headerButtons: [],
      guestClassrooms: []
    }
  },
  watch: {
    student: {
      handler(val) {
        this.localStudent = this.$_.cloneDeep(this.student);
        this.guestClassrooms = this.$_.filter(this.student.classrooms, (classroom)  => {
          return classroom.student_classroom === 'guest';
        });
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      student: 'current/studentArchive',
      courses: 'common/allCourses'
    }),

    registry() {
      return this.student?.registry || this.$_.cloneDeep(Registry);
    },

  },

  methods: {

    ...mapMutations({
      setPartialStudentArchive: 'current/setPartialStudentArchive'
    }),

    detachStudentAsGuest: function(classroom) {
      this.$fullLoading.show();

      this.$api.put(endpoints.STUDENT_HANDLE_CLASSROOM_LINK
          .replace('{id}', this.student.id)
          .replace('{classroomId}', classroom.id)
        + 'detach-as-guest'
      )
        .then((resp) => {

          this.setPartialStudentArchive({
            key: 'classrooms',
            value: resp?.data?.data?.classrooms
          })

          this.$refs.availableGuestClassroomsDatatableRef.reloadDatatable().then().catch();

        })
        .catch((e) => {
          console.log(e);
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    }
  }
}
</script>

<style scoped>

</style>
