<template>
  <octo-table
    show-filters
    :show-id="false"
    :action="endpoints.DATATABLES.availableGuestClassrooms.replace('{id}', student.id)"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
    ref="AvailableClassroomsDatatableRef"
  >
    <template v-slot:course="data">
      <div v-for="(course, index) in data.row.courses" v-bind:key="`course-${index}`">
        <div class="text-truncate">{{ courses[course.id] | optional('title') }}</div>
        <div class="text-uppercase small">{{ $t('datatable.code') }}:
          <label-theme-component>{{ course.code }}</label-theme-component>
        </div>
      </div>
    </template>

    <template v-slot:schedulation="data">
      <div class="small text-uppercase">{{ data.row.schedulation }}</div>
    </template>

    <template v-slot:lessons="data">
      <lessons-container :lessons="data.row.lessons"/>
    </template>

    <template v-slot:actions="data">
      <base-button
        link
        confirm-required
        size="sm text-uppercase"
        @click="attachStudent(data.row)"
        :confirm-title="mode === 'guest'
          ? $t('didactics.attach_student_as_guest')
          : $t('didactics.attach_student_as_main') "
      >
        {{ mode === 'guest' ? $t('didactics.link') : $t('didactics.transfer') }}
      </base-button>
    </template>

    <template slot="custom-filters">
      <div class="col-12">
        <base-input :label="$t('datatable.courses')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.courseIds"
          >
            <el-option
              v-for="course in courses"
              class="select-default text-uppercase"
              :key="course.id"
              :value="course.id"
              :label="course.code + ': ' + course.title"
            />
          </el-select>
        </base-input>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import {endpoints} from "@/routes/endpoints";
import StudentStatuses from "@/pages/Students/resources/studentStatuses";
import StudentArchive from "@/store/current/resources/studentArchive";
import {mapGetters, mapMutations} from "vuex";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {Select, Option} from "element-ui";
import LessonsContainer from "@/pages/Didactics/components/LessonsContainer";

export default {
  name: "AvailableGuestClassroomsDatatable",
  components: {LessonsContainer, OctoIcon, LabelThemeComponent, OctoTable, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      name: 'AvailableClassroomsDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'course', label: 'course', slot: true, fixed: true, width: 400},
        {prop: 'code', label: 'code', align: 'center'},
        {prop: 'schedulation', label: 'schedulation', slot: true, align: 'left', width: 250},
        {prop: 'lessons', label: 'lessons', slot: true, align: 'center', width: 100},
        {prop: 'actions', label: 'actions', width: 150, slot: true, align: 'right', fixed: 'right'},
      ],
      statuses: StudentStatuses
    }
  },
  props: {
    student: {
      type: Object,
      default: () => this.$_.cloneDeep(StudentArchive)
    },
    mode: {
      type: String,
      default: 'guest',
      validator: value => {
        let acceptedValues = [
          'guest',
          'main'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses'
    }),
  },
  methods: {
    ...mapMutations({
      setPartialStudentArchive: 'current/setPartialStudentArchive'
    }),

    attachStudent: function (classroom) {
      this.$fullLoading.show();

      this.$api.put(endpoints.STUDENT_HANDLE_CLASSROOM_LINK
          .replace('{id}', this.student.id)
          .replace('{classroomId}', classroom.id)
        + (this.mode === 'guest' ? 'attach-as-guest' : 'attach-as-main')
      )
        .then((resp) => {

          this.setPartialStudentArchive({
            key: 'classrooms',
            value: resp?.data?.data?.classrooms
          })

        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
          this.$emit('onLinked')
        })
    }
  }
}
</script>

<style scoped>

</style>
