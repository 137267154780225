<template>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group list-group-flush">
        <list-group-item-component :label="$t('fields.date_in')" :value="student.date_in | date"/>
        <list-group-item-component :label="$t('fields.date_out')" :label-width="labelWidth" v-if="editable">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1">
              <el-date-picker
                type="date"
                v-model="student.date_out"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :placeholder="$t('common.select_day')"
                :picker-options="{firstDayOfWeek: 1}"
              />
            </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component
          :label="$t('fields.is_new')"
          :value="$options.filters.optional(student, 'is_new') ? $t('common.yes') : $t('common.no')"
        />
        <list-group-item-component :label="$t('fields.first_contact')" :value="student.first_contact | date"/>
        <list-group-item-component :label="$t('fields.second_contact')" :value="student.second_contact | date"/>
        <list-group-item-component
          :label="$t('fields.weekly_frequency')"
          :value="student | optional('deal.weekly_frequency')"
        />
        <list-group-item-component
          :label="$t('fields.individual_course')"
          :value="$options.filters.optional(student, 'deal.individual_course') ? $t('common.yes') : $t('common.no')"
        />
        <list-group-item-component
          :label="$t('common.accounting_approval')"
          :label-width="labelWidth"
          v-if="showAccountingApproval"
        >
          <template slot="value">
            <el-switch v-model="accountingApproval"/>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('common.status')" :label-width="labelWidth" v-if="editable">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1">
              <el-select
                class="select-default text-uppercase"
                clearable
                :placeholder="$t('fields.choose')"
                v-model="newStatus"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="(status, index) in studentStatuses"
                  :value="status.value"
                  :label="$t('didactics.student_status.' + status.value)"
                  :key="`status-${index}`"
                />
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.preferred_location')" :label-width="labelWidth" v-if="editable">
          <template slot="value">
            <base-input class="mb-0 flex-grow-1">
              <el-select
                clearable
                class="select-default text-uppercase"
                value-key="code"
                :placeholder="$t('common.type_to_search')"
                v-model="locationId"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="(location, index) in locations"
                  :value="location.id"
                  :label="location.alias"
                  :key="`location-${index}`"
                />
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>
      </ul>
    </div>
  </div>
</template>

<script>
import {Select, Option, Switch, DatePicker} from 'element-ui';
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import StudentStatuses from "../resources/studentStatuses";
import {mapGetters} from "vuex";
import ClassroomTypes from "@/pages/Didactics/resources/classroomTypes";

export default {
  name: "StudentArchiveRecapForm",
  components: {
    [DatePicker.name]: DatePicker,
    BaseTextArea,
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select,
    [Switch.name]: Switch,
  },
  data() {
    return {
      labelWidth: 200,
      accountingApproval: false,
      newStatus: '',
      locationId: null,
      studentStatuses: StudentStatuses
    }
  },
  props: {
    editable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    student: function (val) {
      this.accountingApproval = this.$_.cloneDeep(this.student.accounting_approval);
      this.locationId = this.$_.cloneDeep(this.student.location_id);
    },
  },
  computed: {
    ...mapGetters({
      student: 'current/studentArchive',
      courses: 'common/allCourses',
      locations: 'common/locations'
    }),

    showAccountingApproval() {
      return this.courses[this.student?.course_id]?.type === ClassroomTypes.certificate.value;
    }
  },
  methods: {
    getStudentData() {
      const data = {
        accounting_approval: this.accountingApproval,
        location_id: this.locationId
      }

      if (!this.$_.isEmpty(this.newStatus)) {
        data.status = this.newStatus;
      }

      data.date_out = this.student.date_out

      return data;
    }
  },
  mounted() {
    this.newStatus = this.student.status
  }
}
</script>

<style scoped>

</style>
